import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Account, FilterOptions, SortOptions } from '../../types';
import {
  AccountTableWrapper,
  CreateAccountBtnWrapper,
  PageContainer,
  PageContent,
  PageWrapper,
  StyledButton
} from '../../assets/styles/Styled';
import { Space } from 'antd';
import { Search } from '../../components/Search';
import { useAppContext } from '../../context/AppContext';
import { AccountsTable } from './AccountsTable';
import { FilterItems, FilterPanel } from '../ScriptsPage/FilterPanel';
import { extractOrganizationsFromAccounts } from '../../helper';
import ReactPaginate from 'react-paginate';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import useMedia from '../../hooks/useMedia';

interface Filters {
  filter: FilterItems;
}

const AdminDashboard = () => {
  const { fetchAccountResources } = useAppContext();
  const { accounts, loading, pagination, setSearch, setAccounts, fetchAccounts } = useAdminDashboard();

  const [accountOrganizations, setAccountOrganizations] = useState<string[]>([]);
  const { globalLoading } = useAppContext();
  const [filters, setFilters] = useState<Filters>({
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });

  const navigate = useNavigate();

  const location = useLocation();
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.az);
  const { isMobile } = useMedia({ mobileMaxWidth: 767 });

  const handleUpdateAccounts = async () => {
    await fetchAccountResources();
    const params = new URLSearchParams(location.search);
    const page = params.get('page') || 1;
    if (Number(page) === 1) {
      return await fetchAccounts();
    }
    params.set('page', (1).toString());
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    return () => {
      setAccounts(null);
    };
  }, []);

  useEffect(() => {
    const organizationList = extractOrganizationsFromAccounts(accounts?.data || []);
    setAccountOrganizations(organizationList);
  }, [accounts?.data]);

  const filteredAccounts: Account['data'] = useMemo(() => {
    let result = !accounts?.data ? [] : [...accounts.data];
    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      result = result.filter(script => !script.organizations.length);
    }
    if (selectedOrganizations?.length) {
      result = result.filter(script => {
        return script.organizations.some(org => selectedOrganizations.includes(org.organization_name));
      });
    }
    switch (sortOption) {
      case SortOptions.az:
        result.sort((a, b) => a.first_name?.localeCompare(b?.first_name));
        break;
      case SortOptions.za:
        result.sort((a, b) => b.first_name?.localeCompare(a?.first_name));
        break;
      default:
        break;
    }
    return result;
  }, [accounts?.data, filters, sortOption]);

  const handlePageClick = (event: { selected: number }) => {
    const params = new URLSearchParams(location.search);

    params.set('page', (event.selected + 1).toString());
    navigate({ search: params.toString() });
  };
  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          <Space direction="vertical" size={24} className="w-100">
            <CreateAccountBtnWrapper>
              <Link to={'/user-registration'}>
                <StyledButton>Create Account</StyledButton>
              </Link>
            </CreateAccountBtnWrapper>
            <Search placeholder="Created Accounts" onChange={search => setSearch(search)} />
          </Space>
          <FilterPanel
            defaultSortOption={SortOptions.az}
            sortItems={[SortOptions.az, SortOptions.za]}
            organizations={accountOrganizations}
            onSort={opt => setSortOption(opt)}
            onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
          />
          <AccountTableWrapper>
            <AccountsTable
              tableData={filteredAccounts}
              loading={loading || globalLoading.spin}
              getAllAccounts={handleUpdateAccounts}
            />
          </AccountTableWrapper>

          {pagination.page && pagination.totalPages > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={`${isMobile ? '' : 'next '}>`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={isMobile ? 1 : 3}
              marginPagesDisplayed={isMobile ? 1 : 2}
              pageCount={pagination.totalPages}
              previousLabel={`<${isMobile ? '' : ' previous'}`}
              renderOnZeroPageCount={null}
              className="pagination pagination-admin"
              forcePage={pagination.page - 1}
            />
          )}
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default AdminDashboard;
