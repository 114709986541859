import { IAccessControl, LoItemToPreview, Scenario } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const transformLoValues = (scenarioState: Scenario[]) => {
  const loItemToPreview: LoItemToPreview = {};

  scenarioState.forEach(chapter => {
    chapter.blocks.forEach(block => {
      const liveObjects = block.actions?.filter(action => action.action_type === 'live_object');
      if (liveObjects && liveObjects.length > 0) {
        loItemToPreview[block.id] = liveObjects.map(action => ({
          live_object_id: action.live_object_id || '',
          dropdownKey: `live_object-${action.group}`,
          uniq_id: action.uniq_id || '',
          position_x: action.position_x,
          position_y: action.position_y,
          size: action.size,
          action_type: action.id === 'delete_all_live_objects' ? action.id : action.action_type
        }));
      }
    });
  });

  return loItemToPreview;
};

export const transformAccessControls = (item: IAccessControl) => ({
  value: item.entity_id,
  label: item.name || '',
  restriction: item.write_access ? 'edit' : 'view'
});

const matchWhiteboardShowSlide = (str: string) => str.match(/^(WhiteboardShowSlide):(https?.+)$/);

export const transformScenarioActions = (_scenario: Scenario) => {
  const blocksWithId = _scenario.blocks.map(block => ({
    ...block,
    actions:
      block.actions?.map(action => {
        if (action.action_type === 'delete_all_live_objects') {
          return {
            action_type: 'live_object',
            id: action.id,
            group: action.group
          };
        } else if (action.action_type === 'whiteboard' && matchWhiteboardShowSlide(action.id)) {
          const parsedId = matchWhiteboardShowSlide(action.id);
          const [, id, uri] = parsedId!;
          return {
            action_type: 'whiteboard',
            group: action.group,
            id: id,
            uri: uri
          };
        } else {
          return action;
        }
      }) || [],
    id: block.id || uuidv4()
  }));
  return {
    ..._scenario,
    blocks: blocksWithId,
    id: _scenario.id || uuidv4()
  };
};
