import { IPaintAction } from '../../../types';

export const usePaintAction = () => {
  const updatePaintActionToPreview = ({
    prev,
    dropdownKey,
    blockId,
    key
  }: {
    prev: { [key: string]: IPaintAction[] };
    dropdownKey: string;
    blockId: string;
    key: string;
  }): { [key: string]: IPaintAction[] } => {
    const currentBlockActions: IPaintAction[] = prev[blockId] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === dropdownKey);

    const updatedActions: IPaintAction[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? {
                  dropdownKey: dropdownKey,
                  action_type: key
                }
              : action
          )
        : [
            ...currentBlockActions,
            {
              dropdownKey: dropdownKey,
              action_type: key
            }
          ];

    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  return {
    updatePaintActionToPreview
  };
};
