import React, { useCallback, useEffect } from 'react';
import { useScriptForm } from '../ScriptCreateStore';
import { usePreview } from '../../../context/PreviewContext';
import {
  IPaintAction,
  LoItem,
  LoItemToPreview,
  PaintPreview,
  Scenario,
  ScenarioBlock,
  WhiteboardPreviewItems
} from '../../../types';

type EditableBlocks = {
  [key: string]: {
    action: string;
    blockId: string;
    slideIdx: number;
    dropdownKey: string;
  }[];
};
export const useNotePreview = () => {
  const {
    formValues: { scenario: scenarioState, slides }
  } = useScriptForm();
  const {
    editableBlockId,
    previewConfig,
    whiteboardPreview,
    whiteboardPreviewWithOrder,
    whiteboardIconIdx,
    whiteboardEditableBlock,
    loToPreview,
    paintToPreview,
    lastSavedCharacterPosition,
    setPreviewConfig,
    setWhiteboardPreview,
    setEditableBlockId,
    setWhiteboardPreviewWithOrder,
    setWhiteboardIconIdx,
    setWhiteboardEditableBlock,
    setLoPreview,
    setLoToPreview,
    setLastSavedCharacterPosition,
    setPaintToPreview,
    setActiveBlockId,
    setActiveLoToPreview
  } = usePreview();
  const handleSetPreview = useCallback(() => {
    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat = allScenarioBlocks.flat();
    const allScenarioBlocksNote = allScenarioBlocksConcat.findIndex(item => item.type === 'note');
    if (!allScenarioBlocksConcat.length || allScenarioBlocksNote === -1) {
      setPreviewConfig({});
      setWhiteboardPreviewWithOrder([]);
      setWhiteboardPreview({});
      setWhiteboardIconIdx(-1);
      setLoToPreview({});
      setLoPreview([]);
      setLastSavedCharacterPosition('');
      setPaintToPreview({});
      setActiveBlockId(null);
      setActiveLoToPreview([]);
      return;
    }

    if (allScenarioBlocksConcat.filter(item => item.actions?.length).length) {
      setPreviewConfig({});
      const editableBlockIdx = allScenarioBlocksConcat.findIndex(item => item.id === editableBlockId);
      const blocksToPreview = allScenarioBlocksConcat.splice(0, editableBlockIdx + 1);
      (editableBlockId ? blocksToPreview : allScenarioBlocksConcat).map((item, idx, initialArray) => {
        if (item.actions?.length) {
          item.actions.map((action, idx) => {
            setPreviewConfig(prev => ({
              ...prev,
              [action.action_type]: action.id
            }));

            return [];
          });
        }
        return [];
      });
    }
  }, [editableBlockId, scenarioState]);

  const processActions = (actions: WhiteboardPreviewItems[]) => {
    let lastWhiteboardOnIdx = -1;
    let whiteboardOnIdxAfterClear = -1;
    let nextSlideIdx = -1;
    let isWhiteboardOff = false;
    actions.forEach((action, index) => {
      if (action.action === 'WhiteboardOn') {
        if (lastWhiteboardOnIdx === -1 && whiteboardOnIdxAfterClear === -1) {
          action.slideIdx = 0;
        } else if (lastWhiteboardOnIdx !== -1 && whiteboardOnIdxAfterClear === -1) {
          action.slideIdx = nextSlideIdx !== -1 ? nextSlideIdx : lastWhiteboardOnIdx;
        } else if (whiteboardOnIdxAfterClear !== -1) {
          action.slideIdx = nextSlideIdx !== -1 ? nextSlideIdx : whiteboardOnIdxAfterClear;
        }
        lastWhiteboardOnIdx = action.slideIdx;
        nextSlideIdx = lastWhiteboardOnIdx;
        isWhiteboardOff = false;
      } else if (action.action === 'WhiteboardNextSlide') {
        action.slideIdx = lastWhiteboardOnIdx !== -1 ? (slides[nextSlideIdx + 1] ? nextSlideIdx + 1 : slides.length - 1) : -1;
        nextSlideIdx = action.slideIdx;
      } else if (action.action === 'WhiteboardOff') {
        if (!isWhiteboardOff) {
          action.slideIdx = -1;
          lastWhiteboardOnIdx = -1;
          whiteboardOnIdxAfterClear = nextSlideIdx !== -1 ? nextSlideIdx : 0;
          nextSlideIdx = -1;
          isWhiteboardOff = true;
        }
        action.slideIdx = -1;
      } else if (action.action === 'WhiteboardShowSlide' && action.slideIdx < 0) {
        action.slideIdx = nextSlideIdx;
        lastWhiteboardOnIdx = action.slideIdx;
        nextSlideIdx = lastWhiteboardOnIdx;
        isWhiteboardOff = false;
      } else if (action.action === 'WhiteboardShowSlide' && action.slideIdx >= 0) {
        action.slideIdx = action.slideIdx;
        lastWhiteboardOnIdx = action.slideIdx;
        nextSlideIdx = lastWhiteboardOnIdx;
        isWhiteboardOff = false;
      }
    });
    return actions;
  };

  const handleWhiteBoardPreview = useCallback(() => {
    const allWhiteboardValues = Object.values(editableBlockId ? whiteboardEditableBlock : whiteboardPreview);
    const allItems: WhiteboardPreviewItems[] = [];
    allWhiteboardValues.forEach(value => {
      const itemsArray = Object.values(value);
      allItems.push(...itemsArray);
    });
    const concatWhiteboardActions = allItems.flat();
    if (concatWhiteboardActions.length && !concatWhiteboardActions[concatWhiteboardActions.length - 1].action) return;
    const whiteboardActionsWithOrder = processActions(concatWhiteboardActions);
    setWhiteboardPreviewWithOrder(whiteboardActionsWithOrder);
    const currentSlide = whiteboardActionsWithOrder.length - 1;
    const slideIdx = whiteboardActionsWithOrder.length ? concatWhiteboardActions[currentSlide].slideIdx : -1;
    setPreviewConfig(prev => ({ ...prev, whiteboard: slideIdx }));
    if (
      concatWhiteboardActions[currentSlide]?.action === 'WhiteboardShowSlide' &&
      concatWhiteboardActions[currentSlide].slideIdx < 0
    )
      return;
    setWhiteboardIconIdx(slideIdx);
  }, [whiteboardPreview, editableBlockId, whiteboardEditableBlock]);

  const handleUpdateWhiteBoardPreview = useCallback(() => {
    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat: ScenarioBlock[] = allScenarioBlocks.flat().filter(item => item.type !== 'replica');
    if (!allScenarioBlocksConcat.length) return;
    const allEditableBlocks: EditableBlocks = allScenarioBlocksConcat?.reduce((acc: EditableBlocks, block: ScenarioBlock) => {
      const blockId = block.id;
      acc[blockId] = block
        .actions!!.filter(item => item.action_type === 'whiteboard')
        .map((action, idx) => ({
          action: action.id,
          blockId: blockId,
          slideIdx: action.slideIdx ? action.slideIdx : action.uri ? slides?.findIndex(item => item === action.uri) : idx,
          dropdownKey: `whiteboard-${action.group}`
        }));
      return acc;
    }, {});
    setWhiteboardPreview(allEditableBlocks);
  }, [scenarioState, editableBlockId]);

  const handleFilterLoToRender = (lOActions: LoItem[]) => {
    let lastDeleteIndex = -1;

    lOActions?.forEach((action, index) => {
      if (action.action_type === 'delete_all_live_objects') {
        lastDeleteIndex = index;
      }
    });

    return lOActions.slice(lastDeleteIndex + 1);
  };

  const synchronizeData = (firstArray: ScenarioBlock[], secondArrayMap: LoItemToPreview): LoItemToPreview => {
    const filteredSecondArrayMap: LoItemToPreview = {};

    firstArray.forEach(block => {
      if (secondArrayMap[block.id]) {
        filteredSecondArrayMap[block.id] = secondArrayMap[block.id];
      } else {
        filteredSecondArrayMap[block.id] = [];
      }
    });

    return filteredSecondArrayMap;
  };

  const filterLoToPreviewEditableOrNot = (loToPreview: LoItemToPreview, editableBlockId: string): LoItemToPreview => {
    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat: ScenarioBlock[] = allScenarioBlocks.flat().filter(item => item.type !== 'replica');
    const allBlocksNote = allScenarioBlocksConcat.flat().map(item => item.id);

    const editableIndex = allBlocksNote.indexOf(editableBlockId);

    if (editableIndex === -1) {
      return loToPreview;
    }
    const filteredLoToPreview: LoItemToPreview = {};
    for (let i = 0; i <= editableIndex; i++) {
      const blockId = allBlocksNote[i];
      filteredLoToPreview[blockId] = loToPreview[blockId] || [];
    }

    return filteredLoToPreview;
  };

  const initialLoPreview = useCallback((scenarioState: Scenario[]) => {
    const loItemToPreview: LoItemToPreview = {};

    scenarioState.forEach(chapter => {
      chapter.blocks.forEach(block => {
        const liveObjects = block.actions?.filter(action => action.action_type === 'live_object');
        if (liveObjects && liveObjects.length > 0) {
          loItemToPreview[block.id] = liveObjects.map(action => ({
            live_object_id: action.live_object_id || '',
            dropdownKey: `live_object-${action.group}`,
            uniq_id: action.uniq_id || '',
            position_x: action.position_x,
            position_y: action.position_y,
            size: action.size,
            action_type: action.id === 'delete_all_live_objects' ? action.id : action.action_type
          }));
        }
      });
    });

    return loItemToPreview;
  }, []);

  const handleUpdateLoPreview = useCallback(() => {
    if (!Object.keys(loToPreview).length) {
      const initialLoToPreview = initialLoPreview(scenarioState);
      return setLoToPreview(initialLoToPreview);
    }

    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat: ScenarioBlock[] = allScenarioBlocks.flat().filter(item => item.type !== 'replica');
    if (!allScenarioBlocksConcat.length) return;
    const synchronizeWithScenario = synchronizeData(allScenarioBlocksConcat, loToPreview);
    setLoToPreview(synchronizeWithScenario);
  }, [scenarioState, setLoToPreview, loToPreview]);

  const handleLoPreview = useCallback(() => {
    const filteredEditableOrNotEditableLoBlocks = filterLoToPreviewEditableOrNot(loToPreview, editableBlockId || '');

    if (!Object.values(filteredEditableOrNotEditableLoBlocks).filter(item => item !== undefined).length) return;
    const allLoToPreview = Object.values(filteredEditableOrNotEditableLoBlocks).flatMap(actions => actions);
    const loToRenderToPreview = handleFilterLoToRender(allLoToPreview);

    setLoPreview(loToRenderToPreview);
  }, [loToPreview, editableBlockId]);

  function processPaintActions(actions: IPaintAction[]) {
    let currentAction = 'DrawingOff';
    let isActionClear = false;
    let isActionActive = false;
    actions.forEach(item => {
      if (item.action_type === 'DrawingOn' && !isActionActive) {
        isActionActive = true;
        currentAction = 'DrawingOn';
      } else if (item.action_type === 'DrawingOn' && isActionActive) {
        return;
      } else if (item.action_type === 'DrawingClear' && !isActionActive) {
        return;
      } else if (item.action_type === 'DrawingClear' && isActionActive && !isActionClear) {
        currentAction = 'DrawingOn';
      } else if (item.action_type === 'DrawingOff') {
        isActionActive = false;
        currentAction = 'DrawingOff';
      }
    });
    return currentAction;
  }

  const filterPaintActionEditableOrNot = (paintActions: PaintPreview, editableBlockId: string): PaintPreview => {
    const blockIds = Object.keys(paintActions);
    const editableIndex = blockIds.indexOf(editableBlockId);

    if (editableIndex === -1) {
      return paintActions;
    }
    const filteredPaintActions: PaintPreview = {};

    for (let i = 0; i <= editableIndex; i++) {
      const blockId = blockIds[i];
      filteredPaintActions[blockId] = paintActions[blockId];
    }

    return filteredPaintActions;
  };

  const handlePaintPreview = useCallback(() => {
    const currentBlocks = filterPaintActionEditableOrNot(paintToPreview, editableBlockId || '');
    let allPaintBlocks: IPaintAction[] = Object.values(currentBlocks).flat();

    setPreviewConfig(prev => {
      return { ...prev, drawing: processPaintActions(allPaintBlocks) };
    });
  }, [paintToPreview, editableBlockId]);

  const handleUpdatePaintPreview = useCallback(() => {
    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat: ScenarioBlock[] = allScenarioBlocks.flat().filter(item => item.type !== 'replica');
    if (!allScenarioBlocksConcat.length) return;
    const allPaintsBlocksFromScenario: PaintPreview = allScenarioBlocksConcat?.reduce(
      (acc: PaintPreview, block: ScenarioBlock) => {
        const blockId = block.id;
        acc[blockId] = block
          .actions!!.filter(item => item.action_type === 'drawing')
          .map((action, idx) => ({
            action_type: action.id,
            dropdownKey: `drawing-${action.group}`
          }));
        return acc;
      },
      {}
    );

    setPaintToPreview(allPaintsBlocksFromScenario);
  }, [scenarioState]);

  const handleCheckLastPosition = useCallback(() => {
    const allScenarioBlocks = scenarioState.map(item => item.blocks);
    const allScenarioBlocksConcat: ScenarioBlock[] = allScenarioBlocks.flat().filter(item => item.type !== 'replica');
    if (!allScenarioBlocksConcat.length) return;
    const allBlocksNote = allScenarioBlocksConcat.flat();
    const allActions: ScenarioBlock['actions'] = allBlocksNote.reduce(
      (acc, item) => {
        return acc?.concat(item.actions ?? []);
      },
      [] as ScenarioBlock['actions']
    );
    if (allActions?.length) {
      const actionsCharacterPosition = allActions.filter(item => item.action_type === 'character_moving_actions');
      const isContainScenarioLastSavedPosition = actionsCharacterPosition.some(item => item.id === lastSavedCharacterPosition);
      if (!isContainScenarioLastSavedPosition) {
        setLastSavedCharacterPosition('');
      }
    }
  }, [scenarioState]);

  useEffect(() => {
    handleLoPreview();
  }, [loToPreview, editableBlockId]);

  useEffect(() => {
    handleWhiteBoardPreview();
  }, [whiteboardPreview, editableBlockId, whiteboardEditableBlock]);

  useEffect(() => {
    handleSetPreview();
  }, [editableBlockId, scenarioState, handleSetPreview]);

  useEffect(() => {
    handlePaintPreview();
  }, [paintToPreview, editableBlockId]);

  useEffect(() => {
    handleUpdateWhiteBoardPreview();
    handleUpdateLoPreview();
    handleUpdatePaintPreview();
    handleCheckLastPosition();
  }, [scenarioState]);

  return {
    handleSetPreview,
    setEditableBlockId,
    setPreviewConfig,
    setWhiteboardPreview,
    setWhiteboardEditableBlock,
    setLoPreview,
    setPaintToPreview,
    editableBlockId,
    previewConfig,
    whiteboardPreview,
    whiteboardPreviewWithOrder,
    whiteboardIconIdx,
    paintToPreview
  };
};
