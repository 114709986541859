import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DocsRedirectPage = () => {
  const location = useLocation();

  useEffect(() => {
    const isCreateMeeting = location.pathname.includes('create-meeting');
    if (isCreateMeeting) {
      window.location.href = '/docs/create-meeting.html';
    } else {
      window.location.href = '/docs/index.html';
    }
  }, [location]);

  return null;
};

export default DocsRedirectPage;
