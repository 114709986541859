import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Spin } from 'antd';
import { Typography } from 'antd';

import styled from 'styled-components';
import { FooterButtonContainer, PageContent, StyledButton } from '../../assets/styles/Styled';
import { BackLink } from '../../components/BackLink';

import { generateOrganizationsApi, getOrganizationsApi, removeOrganizationsApi } from '../../api/apiWorker';
import { CopyIcon } from '../../assets/icons';

const Container = styled.div`
  padding-top: 32px;
  width: 100%;
  height: 100%;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;

const StyledPageContent = styled(PageContent)`
  margin-top: 84px;
  padding: 0 24px 24px 24px;
  max-width: 906px;
  gap: 24px;
  flex: 1;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const FieldApiKey = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--hint-color);
  padding: 0 14px;
  border-radius: 40px;
  height: 50px;
`;

const IconWrapper = styled.div`
  width: 30px;
  color: var(--hint-color);
  border-radius: 0;
  height: 100%;
  border-left: 1px solid var(--hint-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  cursor: pointer;
  & span {
    width: inherit;
    & > svg {
      rect {
        fill: var(--hint-color);
      }
    }
  }
`;

const EmptyText = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const GenerateOrganizationApiKeyPage = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const { Paragraph, Title } = Typography;

  const handleFetchApiKey = async () => {
    try {
      setLoading(true);
      const { api_key } = await getOrganizationsApi({ organizationId: state.organization_id });
      setApiKey(api_key);
    } catch (error) {
      console.error('[handleFetchApiKey] error');
      setApiKey(null);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      setLoading(true);

      const { api_key } = await generateOrganizationsApi({ organizationId: state.organization_id });
      setApiKey(api_key);
    } catch (error) {
      message.error('Failed to generate key, please try later');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveApiKey = async () => {
    try {
      setLoading(true);
      await removeOrganizationsApi({ organizationId: state.organization_id });
      setApiKey(null);
    } catch (error) {
      message.error('Failed to delete key, try again later');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchApiKey();
  }, []);

  const copyToClipboard = async () => {
    setIsCopied(true);
    try {
      if (apiKey) {
        await navigator.clipboard.writeText(apiKey);
      }
    } catch (err) {
      console.error('Could not copy text: ', err);
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 300);
    }
  };

  return (
    <Container>
      <BackLink title={state ? 'Edit Organization' : 'Create New Organization'} path="/organizations" />
      <StyledPageContent>
        <Spin spinning={loading}>
          <ContentWrapper>
            {apiKey ? (
              <>
                <Title level={2} className="hint-color mb-0 fnt-mulish">
                  Your Api key
                </Title>
                <FieldApiKey className="fnt-mulish">
                  {apiKey}
                  <IconWrapper onClick={copyToClipboard}>
                    {isCopied ? <Spin spinning className="spin-hint" /> : <CopyIcon />}
                  </IconWrapper>
                </FieldApiKey>
              </>
            ) : (
              <EmptyText className="text-align-center fnt-mulish ">
                <Paragraph style={{ fontSize: 18 }} className="extrabold-16 hint-color">
                  You don't have a generated Api key yet.
                </Paragraph>
                <Paragraph style={{ fontSize: 18 }} className="extrabold-16 hint-color">
                  Please generate a key.
                </Paragraph>
              </EmptyText>
            )}
          </ContentWrapper>
        </Spin>
      </StyledPageContent>
      <FooterButtonContainer>
        <StyledButton
          disabled={loading}
          style={{ maxWidth: 858 }}
          className={!apiKey ? 'btn-green' : 'btn-orange'}
          onClick={apiKey ? handleRemoveApiKey : handleGenerateApiKey}
        >
          {!apiKey ? 'Generate Api key' : 'Delete Api key'}
        </StyledButton>
      </FooterButtonContainer>
    </Container>
  );
};

export default GenerateOrganizationApiKeyPage;
